

import styles from "../styles/gallery.module.scss";


import zero from "../images/gallery/gallery0.png";
import title from "../images/gallery/gallerytitle.png";

import s1012 from "../images/gallery/s1012.png";
import s1014 from "../images/gallery/s1014.png";
import s1018 from "../images/gallery/s1018.png";
import s1019 from "../images/gallery/s1019.png";
import s1021 from "../images/gallery/s1021.png";
import s1029 from "../images/gallery/s1029.png";

const Gallery = () => {

    const gallerys = [
        {
            url: s1012,
            AOS: "fade-down"
        },
        {
            url: s1014,
            AOS: "fade-down"
        },
        {
            url: s1018,
            AOS: "fade-down"
        },
        {
            url: s1019,
            AOS: "fade-up"
        },
        {
            url: s1021,
            AOS: "fade-up"
        },
        {
            url: s1029,
            AOS: "fade-up"
        },
    ];

    return (
        <div className={styles.Main} id="Gallery">
            <div className={styles.TitleArea}>
                <img className={styles.Zero} src={zero} alt="" />
                <div className={styles.Title}>
                    <img className={styles.TitleImg} src={title} alt="" />
                </div>
            </div>

            <div style={{padding:`10px`}}>
            <div className={styles.GalleryGrid}>
                { gallerys.map((data, index) => (
                    <div key={index}>
                        <img style={{width:`100%`}} src={data.url} alt="" data-aos={data.AOS} data-aos-duration="1000" />
                    </div>
                ))}
            </div>
            </div>

        </div>
    );
}

export default Gallery;
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import styles from "../styles/ourworks.module.scss";

import shinyl from "../images/ourworks/shinyl.png";
import shinyr from "../images/ourworks/shinyr.png";
import avatar from "../images/ourworks/avatar.png";
import moml from "../images/ourworks/moml.png";
import titleimg from "../images/ourworks/ourwork.png";

import mom from "../images/ourworks/mom.png";
import eggplant from "../images/ourworks/eggplant.png";
import seeker from "../images/ourworks/seeker.png";

import { Context } from "./Wrapper";

const OurWorks = () => {
    const context = useContext(Context);

    return (
        <div className={styles.Main} id="Our Works">
            <div className={styles.ShinyLeft}>
                <img src={shinyl} className={styles.ShinyImg} alt="" />
            </div>

            <img src={titleimg} className={styles.Title} alt="" />

            <div className={styles.WorkMoml}>
                <a href="https://store.steampowered.com/app/1523260/__Myth_of_MistLegacy/" target="_blank" rel="noopener noreferrer" className={styles.MomlHyper}>
                    <img src={moml} className={styles.MomlImg} alt="" />
                </a>
                <div className={styles.MomlTextArea}>
                {context.handlei18n(<FormattedMessage id="i18n.moml" values={{ br: <br/> }} 
                defaultMessage="在被迷霧包圍的時間與永恆之城中，你將扮演家族之子周旋於各陣營之間一起對抗即將到來的末日霧災。
                    獻給喜歡回合制戰棋與選擇導向多結局角色扮演遊戲的玩家。請與我們一起，寫下專屬於你的英雄傳說吧！" />)}
                </div>
            </div>

            <div className={styles.Works}>
                <div className={styles.Eggplant}>
                    <img src={eggplant} alt="" className={styles.WorksImg} data-aos="flip-up" />
                </div>
                <div className={styles.EggplantText}>
                    <div className={styles.WorksText}>
                    <FormattedMessage id="i18n.eggplant" values={{ br: <br/> }} 
                    defaultMessage="Android和Windows Phone上推出的茄式蔬壓遊戲。透過充滿愛意的按壓與茄子互動，在現實和虛幻間探索茄子不為人知的一面。" />                    
                    </div> 
                </div>
                <div className={styles.Seeker}>
                    <img src={seeker} alt="" className={styles.WorksImg} data-aos="flip-up" />
                </div>
                <div className={styles.SeekerText}>
                    <div className={styles.WorksText}>
                    <FormattedMessage id="i18n.seeker" values={{ br: <br/> }} 
                    defaultMessage="單機益智冒險遊戲，和小貓Hime、偵探貓Seeker共同踏上一段跨越星際的旅程，這是個有關追尋的故事，也是個有關找到自己的故事。曾榮登Google Play熱門付費遊戲第四名，Windows Phone每週精選遊戲。" />
                    </div>
                </div>
                <div className={styles.Mom}>
                    <img src={mom} alt="" className={styles.WorksImg} data-aos="flip-up"/>
                </div>
                <div className={styles.MomText}>
                    <div className={styles.WorksText}>
                    <FormattedMessage id="i18n.mom" values={{ br: <br/> }} 
                    defaultMessage="網頁卡片對戰RPG，超過30位英雄，500張卡片與60萬字以上的主、支線劇情故事。玩家能選擇自己中意的英雄或怪物，構築專屬的牌組與技能，以對抗迷霧之王和他的怪物大軍。" />
                    </div>
                </div>
            </div>
            <div className={styles.AvatarArea}>
                <img src={avatar} alt="" className={styles.AvatarImg}/>
            </div>

            <div className={styles.ShinyRight}>
                <img src={shinyr} className={styles.ShinyImg} alt="" />
            </div>
        </div>
    );
}

export default OurWorks;
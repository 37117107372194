import React, { useState, useContext } from "react";
import styles from "../styles/navbar.module.scss";
import navbarlogo from "../images/navbar/logo.png";
import menu from "../images/navbar/menu.png";
import close from "../images/navbar/close.png";

import { scroller } from 'react-scroll'

import { FormattedMessage } from "react-intl";
import { Context } from "./Wrapper";

const NavBar = (props) => {
  const context = useContext(Context);

  const {setPopup} = props;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const buttons = [
    "About Us",
    "Our Works",
    "Gallery",
    "Business Partner",
    "Contact Us",
  ];

  const onNavClick = (id) => {
    scroller.scrollTo(id, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -80
    });
    
    setMenuIsOpen(false)
  }

  const handleButtonsRender = () => {
    return (
      <>
        {buttons.map((button, index) => (
          <React.Fragment key={`frag-${button}`}>
            <button className={styles.Button} key={`button-${button}`} onClick={() => onNavClick(button)}>
              {button} 
            </button>
            {index < (buttons.length - 1) &&
              <div className={styles.Seprate}>|</div>}          
          </React.Fragment>
        ))}
      </>
    );
  };

  const handlepopup =()=>{
    setPopup(true)
  };

  return (
    <>
      {!menuIsOpen && (
      <>
      <div className={styles.Base}></div>
      <div className={styles.NavBar}>
        <div className={styles.Container}>
          <img className={styles.LogoImg} src={navbarlogo} onClick={handlepopup} alt="" />
          <div className={styles.ButtonContainer}>
            {handleButtonsRender()}
            <button
              className={styles.LanguageButton}
              onClick={context.toggleLanguage}
            >
            <FormattedMessage id="i18n.lang" defaultMessage="En" />
            </button>
          </div>
          <img
            onClick={() => setMenuIsOpen(!menuIsOpen)}
            className={styles.MenuButton}
            src={menu}
            alt=""
          />
        </div>
      </div>
      </>
      )}
      {menuIsOpen && (
        <div className={styles.Menu}>
          <div className={styles.Container}>
            <img className={styles.LogoImg} src={navbarlogo} alt="" />
            <img
              onClick={() => setMenuIsOpen(!menuIsOpen)}
              className={styles.MenuButton}
              src={close}
              alt=""
            />
          </div>
          <div className={styles.MenuButtonContainer}>
            {handleButtonsRender()}
            <button
              className={styles.LanguageButton}
              onClick={ () => {
                 context.toggleLanguage();
                 setMenuIsOpen(false);
                }}
            >
              <FormattedMessage id="i18n.lang" defaultMessage="En" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default NavBar;

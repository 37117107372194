import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import styles from "../styles/aboutus.module.scss";

import bgimg from "../images/aboutus/background.png";
import firstimg from "../images/aboutus/first.png";
import titleimg from "../images/aboutus/aboutus.png";

import { Context } from "./Wrapper";


const AboutUs = () => {
    const context = useContext(Context);
    
    return (
        <div className={styles.Main} id="About Us" >
            <img src={bgimg} alt="" className={styles.Img} />
            <div className={styles.Page} >
                <div className={styles.InnerPage}>
                    <div className={styles.ShowFisrtArea}>
                        <img className={styles.FirstImg} src={firstimg} alt="" />
                    </div>
                    <div className={styles.ShowAboutArea}>
                        <img src={titleimg} alt="" className={styles.TitleImg} />
                        <div className={styles.AboutusText}>
                        { context.handlei18n(<FormattedMessage id="i18n.aboutus" values={{ br: <br/>}} defaultMessage="
                            我們是來自台灣的獨立遊戲團隊，由一群熱情且專業的開發者組成。對我們來說，遊戲不僅只是遊戲，更是代表一群人一塊土地的藝術與文化。
                            透過遊戲，我們將能與世界上每個角落的玩家建立連結，共同創造一個更美好也更真實的未來。
                            我們是赫米兔娛樂，這是我們的故事，也是一場充滿驚奇的冒險。願你與我們同行，並讓我們在未來共享這份榮耀與收穫。" />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
import styles from "../styles/partner.module.scss";

import title from "../images/partner/partnertitle.png";
import bgimg from "../images/partner/partnerbg.png";

import p1 from "../images/partner/p1.png";
import p2 from "../images/partner/p2.png";
import p3 from "../images/partner/p3.png";
import p4 from "../images/partner/p4.png";
import p5 from "../images/partner/p5.png";
import p6 from "../images/partner/p6.png";
import p7 from "../images/partner/p7.png";
import p8 from "../images/partner/p8.png";


const Partner = () => {

    const partners = [
        {
            url: p1,
            name: "臺北地方異聞工作室",
            corp: "跨界合作"
        },
        {
            url: p2,
            name: "海穹文化",
            corp: "跨界合作"
        },
        {
            url: p3,
            name: "奇異果文創",
            corp: "圖文出版"
        },
        {
            url: p4,
            name: "英特衛多媒體",
            corp: "遊戲發行"
        },
        {
            url: p5,
            name: "bilibili遊戲",
            corp: "遊戲發佈"
        },
        {
            url: p6,
            name: "優必達 Ubitus",
            corp: "雲端遊戲、5G串流發行"
        },
        {
            url: p7,
            name: "中華電信",
            corp: "媒體廣宣"
        },
        {
            url: p8,
            name: "璐米娜 Lumina",
            corp: "遊戲客串、直播宣傳"
        },
    ];

    return (
        <div className={styles.Main} id="Business Partner">
            <img src={bgimg} alt="" className={styles.Img} />
            <div className={styles.Page} >
                <div className={styles.InnerPage}>
                    <img src={title} alt="" className={styles.TitleImg} />
                    <div className={styles.PartnerArea} >
                        <div className={styles.PartnerGrid}>
                            { partners.map((data, index)=> (
                                <div key={index} className={styles.PartnerGridItem} >
                                    <img src={data.url} alt="" className={styles.PartnerImg} />
                                    <div className={styles.PartnerFont} >{data.name}</div>
                                    <div className={styles.CorpFont} >{data.corp}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Partner;
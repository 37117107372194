import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination, Autoplay } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import styles from "../styles/carousel.module.scss";

import mom from "../images/home/carousel/mom.png";
import seeker from "../images/home/carousel/seeker.png";
import toucheggplant from "../images/home/carousel/toucheggplant.png";
import momlagecy from "../images/home/carousel/momlegacy.png";

const Carousel = () =>  {

    const pagination = {
        clickable: true,
    };

    const autoplay = {
        delay: 4200,
        disableOnInteraction: false,
        // pauseOnMouseEnter: true,
    };

    const slideImages = [
        {
            url: mom,
            caption: 'Myth Of Mist'
        },
        {
            url: seeker,
            caption: 'Seeker'
        },
        {
            url: toucheggplant,
            caption: 'Touch Egg Plant'
        },
        {
            url: momlagecy,
            caption: 'Myth Of Mist Legacy'
        },
    ];

    return (
        <Swiper
            pagination={pagination}
            autoplay={autoplay}
            modules={[Pagination, Autoplay]}
            className={styles.MySwiper}            
        >
            {slideImages.map((slideImage, index) => (
                <SwiperSlide key={slideImage.caption}>
                    <img src={slideImage.url} alt="" className={styles.SlideImg}/>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default Carousel;

import styles from "../styles/logos.module.scss";

import steam from "../images/logos/steam.png";
import fb from "../images/logos/fb.png";
import mail from "../images/logos/mail.png";
import logo from "../images/logos/logo.png";
import ig from "../images/logos/ig.png";
import twitter from "../images/logos/twitter.png";



const Logos = () => {

    return (
        <div className={styles.Main} id="Contact Us">
            <img src={logo} alt="" />
                <div className={styles.Links}>
                    <a href="https://store.steampowered.com/search/?developer=%E8%B5%AB%E7%B1%B3%E5%85%94%E5%A8%9B%E6%A8%82Skvader%20Entertainment" target="_blank" rel="noopener noreferrer">
                        <img src={steam} alt="" />
                    </a>
                    <a href="https://www.facebook.com/mythofmist/" target="_blank" rel="noopener noreferrer">
                        <img src={fb} alt="" />
                    </a>
                    <a href="https://www.instagram.com/skvadergames/" target="_blank" rel="noopener noreferrer">
                        <img src={ig} alt="" />
                    </a>
                    <a href="https://twitter.com/skvadergames" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="" />
                    </a>
                    <a href="mailto: skvaderherald@gmail.com">
                        <img src={mail} alt="" />
                    </a>
                </div>
                <div className={styles.CopyRight}>© 2022 Skvader Studio. All rights reserved.</div>
        </div>
    );
}

export default Logos;
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from "./styles/app.module.scss";
import NavBar from "./components/NavBar";
import Carousel from "./components/Carousel";
import AboutUs from "./components/AboutUs";
import OurWorks from "./components/OurWorks";
import Gallery from "./components/Gallery";
import Partner from "./components/Partner";
import Logos from "./components/Logos";


const App = () => {

  // popup開啟的狀態
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    AOS.init({
      startEvent: `DOMContentLoaded`,
      mirror : true,
      duration : 1500,
      delay : 180,
      easing: `ease-out-cubic`
    });
    AOS.refresh();
  }, []);

  return (
      <div className={styles.App}>
        <NavBar
          setPopup={setPopup}
        />
        <Carousel />
        <AboutUs />
        <OurWorks />
        <Gallery />
        <Partner />
        <Logos />
      </div>
  );
};

export default App;

import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import Tw from '../lang/tw.json';
import En from '../lang/en.json';

export const Context = React.createContext();

const local = navigator.language;

let lang;
switch (local) {
    case `en-US`:
        lang = En;
        break;
    case `zh-TW`:
        lang = Tw;
        break;
    default:
        lang = En;
}

const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function toggleLanguage() {
        let newlocale = "zh-TW";
        if (locale === "zh-TW") newlocale = "en-US";

        setLocale(newlocale);

        switch (newlocale) {
            case `en-US`:
                setMessages(En);
                break;
            case `zh-TW`:
                setMessages(Tw);
                break;
            default:
                setMessages(En);
        }
    }

    function handlei18n(body) {
        switch (locale) {
            case `zh-TW`:
                return (<zh-TW>{body}</zh-TW>);
            default:
                return (<en-US>{body}</en-US>);
        }
    }

    return (
        <Context.Provider value = {{locale, toggleLanguage, handlei18n}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}


export default Wrapper;